// vue
import { ref, computed } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, context) {
// store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const notification = useNotification();

    // vars
    const { _ } = window;
    const tradingViewValue = ref('trading_view_signal');
    const dataForm = bots.dataForm;
    const show = ref(dataForm.settings.tradingview_enabled);

    const copy = s => {
        navigator.clipboard.writeText(s);
        notification.success({
            content: t('copied'),
            duration: 2500,
            keepAliveOnHover: true,
        });
    };

    const tradingViewOptions = computed(() => bots.tradingViewOptions.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const changeJSON = (str, payload) => {
        const parsed = JSON.parse(str);

        if (payload?.length) {
            const op = payload[0];
            const data = payload[1];

            if (op == 'change' || op == 'add') {
                Object.keys(data).forEach(key => {
                    parsed[key] = data[key];
                });
            }
        }
        
        const result = Object.entries(parsed).reduce((accum, [key, value], index) => {
            const isNumber = +value;
            const last = index == Object.keys(parsed).length - 1;

            return accum + `\n\t"${key}": ${isNumber != isNumber ? '"' + value + '"' : isNumber}` + (last ? '\n}' : ',');

        }, '{');

        return result;
    };

    const cutOffURL = url => {
        const u = new URL(url);

        return u.origin + u.pathname;
    };

    return {
        gl,
        show,
        bots,
        dataForm,
        tradingViewValue,
        tradingViewOptions,
        copy,
        changeJSON,
    };
}